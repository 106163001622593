<template>
  <v-container>
    <v-card class="mx-auto cardstyle" max-width="800" raised>
    <v-img
      height="250"
      src="/static/header.jpg"
    ></v-img>      
    <template v-if="step === 1">
      <v-card-title>¡Enhorabuena por llegar hasta aquí!</v-card-title>
      <v-card-text>
        <p class="text-body-1">
            Si estás a punto de realizar este formulario significa que has completado con éxito la formación del programa GIRA Mujeres V Edición.<br/><br/>
            Estamos seguras de que en este tiempo has podido trabajar en tu idea o proyecto, dedicarle el tiempo que necesitaba a repensar y ajustar, y ahora ¿cuál es el próximo paso? Pues nada más y nada menos que presentar tu proyecto para ser elegido como Finalista de GIRA 5ª Edición.<br/><br/>
            Si crees que tu proyecto o idea de emprendimiento se merece una plaza en esta nueva etapa, completa el siguiente formulario y adjunta el entregable. Recuerda que será la información que se valorará de tu proyecto, por lo que te aconsejamos que le dediques tiempo y cariño.<br/><br/>
            ¿Comenzamos? ¡Ánimo!
        </p>
      </v-card-text>
      <v-card-actions>
        <v-container>
        <v-row>
        <v-col class="text-center" cols="12"><v-btn color="error" large :block="$vuetify.breakpoint.xsOnly" @click="step = 2"> Comenzar</v-btn></v-col>
        </v-row>
        </v-container>
      </v-card-actions>
    </template>
    <template v-if="step === 2">
        <ValidationObserver ref="observer" v-slot="{ invalid, passes }">
      <v-card-title>Quiero Emprender</v-card-title>
      <v-card-text>
          Bienvenida a la fase de selección de finalistas de la categoría ``Quiero emprender´´. Nos gustaría conocer tu idea de negocio o proyecto de emprendimiento ¿Empezamos?
      </v-card-text>
      <v-card-text>
          <form @submit.prevent="passes(submit)">
            <v-row>
              <v-col cols="12">
                <ValidationProvider v-slot="{ errors }" rules="required|email">
                  <v-text-field
                    v-model="$store.state.salidaQuieroEmprender.registryField_29005"
                    label="Email con el que has participado"
                    type="email"
                    required
                    filled
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿En qué medida consideras que la formación y el acompañamiento te ha ayudado a desarrollar tu idea? Valora del 1 al 5, donde 1 es “No me ha ayudado nada” y 5 es “Me ha ayudado muchísimo”. 
                    </p>
                    <v-radio-group :error-messages="errors" v-model="$store.state.salidaQuieroEmprender.registryField_29007">
                        <v-radio color="error" label="1" value="1" />
                        <v-radio color="error" label="2" value="2" />
                        <v-radio color="error" label="3" value="3" />
                        <v-radio color="error" label="4" value="4" />
                        <v-radio color="error" label="5" value="5" />
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿A cuál de los siguientes sectores pertenece tu idea o proyecto de emprendimiento?
                    </p>
                    <v-radio-group :error-messages="errors" v-model="$store.state.salidaQuieroEmprender.registryField_29008">
                        <v-radio color="error" label="Agrícola (producción y cultivos, ganadería, otros)" value="Agrícola (producción y cultivos, ganadería, otros)" />
                        <v-radio color="error" label="Alimentación" value="Alimentación" />
                        <v-radio color="error" label="Industrial" value="Industrial" />
                        <v-radio color="error" label="Energético" value="Energético" />
                        <v-radio color="error" label="Servicios ( mantenimiento, limpieza, cuidados )" value="Servicios ( mantenimiento, limpieza, cuidados )" />
                        <v-radio color="error" label="Marketing y comunicación" value="Marketing y comunicación" />
                        <v-radio color="error" label="Texto ( producción y diseño )" value="Texto ( producción y diseño )" />
                        <v-radio color="error" label="Consultoría" value="Consultoría" />
                        <v-radio color="error" label="Hostelería" value="Hostelería" />
                        <v-radio color="error" label="Comercio minorista" value="Comercio minorista" />
                        <v-radio color="error" label="Artesanía / Producción artesanal" value="Artesanía / Producción artesanal" />
                        <v-radio color="error" label="Economía circular, reciclaje, y reutilización" value="Economía circular, reciclaje, y reutilización" />
                        <v-radio color="error" label="Ocio y tiempo libre" value="Ocio y tiempo libre" />
                        <v-radio color="error" label="Otros" value="Otros" />
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Vamos a centrarnos ahora en tu idea de emprendimiento. ¿Nos podrías resumir brevemente de qué se trata? Para ello te animamos a que plantees tu respuesta siguiendo este orden: Mi idea se llama... Y tiene por objetivo...  Está dirigida a… Va a tener su ámbito de impacto geográficamente en…. Y es novedosa o única porque… 
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29009"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Para conocer más en detalle, ¿cuáles consideras que son los puntos fuertes de tu proyecto de emprendimiento? Explícanos en detalle como máximo 3 puntos fuertes. 
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29010"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                       Durante este tiempo de formación, habrás trabajado en identificar cuál es tu cliente final. Vamos a identificar a continuación al menos 2 ``clientes tipo´´ para tu proyecto de emprendimiento. Para cada uno de los casos identifica: persona, género, edad, nacionalidad, nivel económico, nivel de estudios, a qué se dedica, estilo de vida y hábitos de consumo
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29011"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      ¿Has testeado o validado tu idea o proyecto de emprendimiento con tus posibles clientes, aliados, proveedores, familia o amigos/as? (29012)
                    </p>
                    <v-radio-group :error-messages="errors" v-model="$store.state.salidaQuieroEmprender.registryField_29012">
                        <v-radio color="error" label="Sí" value="Sí" />
                        <v-radio color="error" label="No, pero lo haré en el futuro" value="No, pero lo haré en el futuro" />
                        <v-radio color="error" label="No, creo que no es necesario" value="No, creo que no es necesario" />
                    </v-radio-group>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      En caso de haberlo testado o validado cuéntanos muy brevemente que has hecho para el testeo o la validación de tu idea
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29013"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      En este espacio te invitamos a que nos cuentes por qué tu idea es diferente al resto. ¿Qué soluciones presenta que son novedosas? ¿Por qué tu cliente te va a elegir a ti en lugar de a la competencia?  
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29014"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Sabemos que poner en marcha un proyecto no es nada fácil ¿Qué 2 retos crees que tienes para poner en marcha la idea? (No buscamos respuestas únicamente asociadas a la falta de dinero, seguro que hay más cosas que te frenan o te hacen dudar).
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29015"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <p class="body-2">
                      Háblanos sobre ti, sobre tu pasión por el emprendimiento y cuéntanos por qué mereces ser finalista de GIRA Mujeres 5º ed. 
                    </p>
                    <v-textarea
                      v-model="$store.state.salidaQuieroEmprender.registryField_29016"
                      required
                      filled
                      auto-grow
                      counter="1000"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
              </v-row>

          </form>

      </v-card-text>
      <v-card-actions>
        <v-container>
        <v-row>
        <v-col class="text-center" cols="12"><v-btn color="error" large :block="$vuetify.breakpoint.xsOnly" :disabled="invalid" @click="gracias()"> Enviar</v-btn></v-col>
        </v-row>
        </v-container>
      </v-card-actions>
        </ValidationObserver>

    </template>
    <template v-if="step === 3">
      <v-card-title>¡Gracias por tu tiempo!</v-card-title>
      <v-card-text>
        <p class="text-body-1">
            Te confirmamos que tu formulario ha sido registrado con éxito. En las próximas semanas un equipo de expertos en materia de emprendimiento valorará tu formulario y en el caso de ser seleccionada como finalista te contactaremos desde el equipo de GIRA Mujeres para contarte la noticia. <br/> <br/>
            ¡Hasta pronto! <br/> <br/>
            <i>Equipo GIRA Mujeres de Coca-Cola</i>
        </p>
      </v-card-text>


    </template>
    </v-card>
  </v-container>
</template>
<script>
import { required, email } from "vee-validate/dist/rules";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import firebase from 'firebase/app'
import 'firebase/database'

export const db = firebase
  .initializeApp({ databaseURL: 'https://giramujeres-preinscripcion.firebaseio.com' })
  .database()

extend("required", { ...required, message: "Este campo es obligatorio." });
extend("email", { ...email, message: "Este campo debe ser un email válido." });

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  name: "Home",
  methods: {
    encodeAsFirebaseKey: function (string) {
      return string.replace(/%/g, '%25')
        .replace(/\./g, '%2E')
        .replace(/#/g, '%23')
        .replace(/\$/g, '%24')
        .replace(/\//g, '%2F')
        .replace(/\[/g, '%5B')
        .replace(/\]/g, '%5D')
    },

    submit() {
      this.$refs.observer.validate();
    },
    async gracias() {
      this.step = 3
        let ref = firebase.database().ref('/adios-2021/' + this.encodeAsFirebaseKey(this.$store.state.salidaQuieroEmprender.registryField_29005.trim().toLowerCase()))
        try {
            await ref.set(this.$store.state.salidaQuieroEmprender)
        } catch (error) {
          if (error.code === 'PERMISSION_DENIED') {
            alert('Este email ya esta preinscrito.');
          } else {
            alert('Error realizando preinscripción. Si el error persiste ponte en contacto con nosotros.')
          }

        }
    }
  },
  data: () => {
      return {
          step: 1
      }
  }
}
</script>